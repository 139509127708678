// Here you can add other styles
.icon-button {
  &:hover {
    cursor: pointer;
  }
}

.box-logo {
  border: 1px solid rgb(201, 202, 206);
  border-image: initial;
  height: 120px;
  width: 360px;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  justify-content: center;
}

.box-identification {
  border: 1px solid rgb(201, 202, 206);
  border-image: initial;
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  justify-content: center;

  &.zoom-in {
    cursor: zoom-in;
  }
}

.group-button-identification {
  flex: 1;
}

.flex-1 {
  flex: 1;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.border {
  border: 1px solid #d8dbe0;
  border-radius: 4px;
}

.card {
  box-shadow: 0px 0px 50px -15px rgb(0 0 0 / 33%);
}

.c-header-nav .dropdown-lang-item {
  min-width: 100px;
}

.c-body {
  background: #f5f5f5;
}

.col-form-label {
  font-weight: 500;
  color: #333;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-left {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.flex-left-center {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.flex-right {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.form-required {
  font-size: 18px;
  color: #e55353;
  line-height: 1;
  height: 12px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.form-control {
  color: black;
}

.custom-multi-select {
  width: calc(100% - 42px);

  &.invalid {
    border: 1px #e55353 solid;
  }
}

.custom-multi-select-2 {
  width: calc(100% - 42px);
  height: 35px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: black;

  &.invalid {
    border: 1px #e55353 solid;
  }
}

.custom-dropdowns {
  .dropdown-toggle::before {
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .dropdown-toggle::after {
    display: inline-block;
    content: "";
    border-width: 0;
  }

  .dropdown-toggle {
    width: 35px;
    height: 35px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding-right: 8px;
  }
}

.c-datatable-filter {
  .form-control {
    width: 100%;
  }
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.text-danger {
  color: #e55353;
}

.bg-danger-light {
  background-color: #ffeeed;
}

.color-picker-container {
  display: flex;
  border: 1px solid;
  height: 35px;
  color: #768192;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding-left: 5px;
  padding-top: 2px;

  .input-color-picker {
    color: transparent;
    width: 25px;
    border: none;
    height: 30px;
    margin: 0px;
    padding: 0px;
    background-color: transparent;
    -webkit-tap-highlight-color: transparent;
  }

  .input-color-text {
    color: currentColor;
    width: 100%;
    border: 0;
    height: unset;
    margin: 0;
    display: block;
    padding: 8px 16px 8px 8px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
  }

  .no-outline:focus {
    outline: 0;
  }
}

.color-picker-container-focus {
  box-shadow: rgba(99, 93, 255, 0.25) 0px 0px 0px 0.25em;
  border-color: #321fdb60;
  border-width: 1px;
}

.file-uploader {
  width: calc(100% - 42px);
}

.datepicker-modal {
  box-shadow: 0px 0px 50px -15px rgb(0 0 0 / 33%);
  position:fixed!important;
  top:auto;
  right:auto;
  left:auto;
  bottom:0;
  animation:fadein .5s;

  .datepicker.default {
    background-color: white;
  }
}

.button-cloudinary-upload {
  width: calc(100% - 84px);
}

@keyframes fadein {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}


.reward-description-content {
  background-color: white;
  color: black;
  border: 1px solid black;
  padding: 12px;
  box-shadow: 0px 0px 50px -15px rgb(0 0 0 / 33%);
}

.font-weight-bold {
  font-weight: bold;
}

.field_set {
  border: 1px solid #d8dbe0;
}

.pledge {
  .pledge-add-icon {
    display: flex;
    align-items: center;
    .icon {
      cursor: pointer;
    }
  }
}

.pledge-add-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #321fdb;
  color: white;

  &.minus {
    background-color: #e55353;
    cursor: pointer;
  }
}

.card-pledge-title {
  display: flex;
  align-items: center;
}

.nav-group-items {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 27px;
    width: 3px;
    height: 100%;
    border-left: 1px dashed #dcdcdc5e;
  }

  .nav-item {
    .nav-link {
      padding-left: 35px!important;
      .sub-nav-icon {
        height: 14px;
        margin-right: 15px;
      }
    }
  }
}

.note-item {
  border-bottom: 1px dotted #ccc;
  .icon-btn {
    cursor: pointer;
    color: #adb5bd;

    &:hover {
      color: black;
    }
  }
}

.currency-input {
  border: 1px solid #b1b7c1;
  width: 100%;
  border-radius: 4px;
  padding: 6px 12px;
}

.text--small {
  font-size: 0.75rem !important;
}

.custom-stat-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-height: 130px;
  justify-content: flex-end;
  overflow: hidden;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  position: relative;
  align-items: center;
  padding: 30px 20px;

  &:hover {
    cursor: pointer;
    transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -o-transform: translateY(-3px);
  }

  .custom-stat-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;

    .fa-icon {
      font-size: 72px;
      color: rgba(255, 255, 255, 0.15);
      margin-left: -15px;
      margin-bottom: -4px;
    }
  }
  .custom-stat-detail {
    text-align: right;
    width: auto;

    .amount,
    .currency-sign {
      color: #ffffff;
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
    }

    .description span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 300;
      display: inline-block;
      margin-top: 5px;
    }
  }
}

.widget-three {
  padding: 30px 30px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.widget-three:hover {
  -webkit-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.widget-three__icon {
  width: 90px;
  height: 90px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
}

.widget-three__icon i {
  font-size: 46px;
  color: #ffffff;
}

.widget-three__content {
  margin-top: 25px;

  .text-amount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.widget-three__content .numbers {
  font-size: 24px;
  font-weight: 600;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.box-radius-10 {
  border-radius: 10px;
}

.input-group {
  .react-datepicker-wrapper {
    width: calc(100% - 42px);
  }
}

.loan-date-picker {
  width: 150px;
}

.loan-date-range-picker {
  .react-datepicker-wrapper {
    width: 50%;
  }
}

.icon-circle-success {
  color: #2eb85c;
}

.icon-circle-danger {
  color: #e55353;
}

.icon-circle-primary {
  color: #1e9ff2;
}

.min-width-130 {
  min-width: 130px;
}

.min-width-200 {
  min-width: 200px;
}

.color-danger {
  color: #e55353;
}

.color-primary {
  color: #1e9ff2;
}

.color-secondary {
  color: #9da5b1;
}

.color-warning {
  color: #f9b115;
}

.custom-avatar-inner {
  overflow: hidden;

  .badge {
    top: 0;
  }

  .badge {
    position: relative;
    top: -1px;
  }
  .border {
    border: 1px solid #dee2e6 !important;
  }
  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    padding-right: 0.4em;
    padding-left: 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .d-inline-block {
    display: inline-block !important;
  }

  .filter-option-inner-inner img.taskEmployeeImg {
    width: 20px;
    height: 20px;
  }
  .badge img.taskEmployeeImg {
    width: 25px;
    height: 25px;
  }
  img.taskEmployeeImg {
    width: 30px;
    height: 30px;
  }
  .taskEmployeeImg {
    width: 25px;
    height: 25px;
    display: inline-block;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .taskEmployeeImg {
    border: 1px solid #e8eef3;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
}

.text-lightest {
  color: #99A5B5;
}

@media (max-width: 767.98px) {
  .sidebar-nav {
    max-height: 700px;
  }
}
