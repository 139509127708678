.role-container-drag {
  position: relative;

  //.available-role,
  //.droppable {
  //  z-index: 10;
  //}

  .drag-role-list {
    height: 100px;
    overflow: auto;
    padding-left: 10px;
    padding-top: 10px;
    border: 1px solid #d8dbe0;
    border-radius: 1px;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

    .draggable {
      &:hover {
        background-color: rgba(99, 93, 255, 0.25);
      }

      &:focus {
        background-color: rgba(99, 93, 255, 0.25);
      }

      &.selected {
        background-color: rgba(99, 93, 255, 0.25);
      }
    }
  }

  .drag-icon {
    position: absolute;
    top: 35%;
    left: calc(50% - 22px);
    width: 45px;
    color: #bbb;
  }
}
