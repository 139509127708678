.borrower {
  .card-avatar {
    background: #6373c1;
    color: white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .icon-circle {
      border: 1px solid white;
      border-radius: 50%;
      background: #3f51b5;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .card-navigation {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .card-navigation-header {
      text-transform: uppercase;
    }

    .card-navigation-bar {
      width: 100%;
      background: transparent;
      color: black;

      .card-navigation-item {
        padding: 10px 20px;
        cursor: pointer;

        &.active {
          background: #efecec;
        }
      }
    }
  }

  .card-profile {
    .list-group-item {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      padding: 15px 0;
    }
  }

  .list-group-item {
    padding-left: 15px!important;
  }
}

.card-borrower-loan {
  .nav-tabs {
    .nav-item {
      cursor: pointer;
    }
  }
}
