.col-org-active {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  .form-label {
    margin-bottom: 0;
  }
}
